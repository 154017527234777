<template>
  <section class="section-plan" :style="{'background-image': backgroundImage ? `url(${require('@/assets/images/' + backgroundImage)})` : null}">
    <div class="content-wrapper">
      <div class="intro-wrapper flat">
        <h2 v-if="title" v-html="title" />
        <p v-if="body" v-html="body" />
      </div>
      <div :class="isFramed ? 'frame blurry' : ''">
        <div class="details-wrapper row">
          <div class="price-details column">
            <p><strong>{{ pricing.title }}</strong></p>
            <div class="frame compact bevel">
              <p class="price">
                <strong>
                  <span class="highlight">{{ pricing.price }}</span>
                  {{ pricing.label }}
                </strong>
              </p>
              <p class="services-included" v-html="pricing.conditions" />
              <a :href="pricing.cta.href" :target="pricing.cta.target" class="button">{{pricing.cta.label}} <IconArrow v-if="pricing.cta.arrowIcon" /></a>
            </div>
          </div>
          <div class="services-details column">
            <ul v-if="list.length > 0">
              <li v-for="(listItem, indexList) in list" v-bind:key="indexList">
                <font-awesome-icon :icon="['far', 'check-circle']" /> <span v-html="listItem.body" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import IconArrow from '@/components/elements/_Arrow.vue'

  export default {
    name: 'Plan',
    components: {
      IconArrow
    },
    data: () => ({}),
    props: {
      title: {
        type: String,
        default: null
      },
      body: {
        type: String,
        default: null
      },
      pricing: {
        type: Object,
        default: () => ({ title: null, price: null, label: null, conditions: null, cta: null })
      },
      list: {
        type: Array,
        default: () => [{ body: null }]
      },
      backgroundImage: {
        type: String,
        default: null
      },
      isFramed: {
        type: Boolean,
        default: null
      },
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
@use "@/style/_mixins" as *;
@use "@/style/_variables" as *;

  .section-plan{
    .content-wrapper{
      & > .frame{ // Only used if isFramed prop is true
        @include screens-m { padding: $content_padding_m * 0.75; }
      }

      .details-wrapper{
        .price-details{
          @include flex(none); // Make sure the specified width will be respected
          width: 250px;
          @include screens-s { 
            margin: 0 auto $content_padding_s auto;
          }
          @include screens-m {
            width: 250px;
            margin-right: $content_padding_m * 0.5;
          }
          @include screens-l {
            width: 350px;
            margin-right: $content_padding_l; 
          }
          
          & > .frame{
            .price{
              .highlight{
                display: block;
                color: $colors-blue-navy;
                font-size: 80px;
                line-height: 1;
                font-weight: 800;
                @include screens-m { font-size: 85px; }
                @include screens-l { font-size: 100px; }
              }
            }

            .services-included{
              font-size: $note_font-size_s;
              margin: $content_padding_s * 0.5 0;
              @include screens-m { 
                font-size: $note_font-size_m;
                margin: $content_padding_m * 0.5 0;
              }
              @include screens-l { 
                font-size: $note_font-size_l;
                margin: $content_padding_l * 0.5 0;
              }
            }
          }
        }

        .services-details{
          text-align: left;

          ul{
            li{
              position: relative;
              color: $colors-blue-navy;
              font-weight: 600;
              margin-top: 15px;
              padding-left: 38px;
              @include screens-m {
                padding-left: 42px; 
                margin-top: 17px; 
              }
              @include screens-l { 
                padding-left: 45px;
                margin-top: 20px; 
              }

              svg{
                position: absolute;
                top: 0;
                left: 0;
                font-size: 22px;
                width: 30px;
                @include screens-m {font-size: 25px }
                @include screens-l { font-size: 28px }
              }
            }
          }
        }
      }
    }
  }
</style>