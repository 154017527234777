<template>
  <section class="section-timeline">
    <div class="content-wrapper">
      <div class="intro-wrapper">
        <h2 v-if="title" v-html="title" />
        <p v-if="body" v-html="body" />
      </div>
      <div class="timeline-wrapper" v-if="steps.length > 0">
        <div class="step row" v-for="(step, indexStep) in steps" v-bind:key="indexStep">
          <div class="date column">
            <p>{{ step.date }}</p>
          </div>
          <div class="timeline column">
            <div class="line"></div>
            <div class="bullet-point"></div>
          </div>
          <div class="details column">
            <h3 v-html="step.title" />
            <p v-html="step.body" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Timeline',
    components: { },
    data: () => ({}),
    props: {
      title: {
        type: String,
        default: null
      },
      body: {
        type: String,
        default: null
      },
      steps: {
        type: Array,
        default: () => [{ date: null, title: null, body: null }]
      },
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
@use "@/style/_mixins" as *;
@use "@/style/_variables" as *;

  .section-timeline{
    .content-wrapper{
      .timeline-wrapper{
        max-width: $content_max-width_m;
        margin: 0 auto;

        .step{
          align-items: initial;
          @include screens-s { @include flex-direction(row); }

          .date{
            @include flex(none); // Make sure the specified width will be respected
            width: 20%;
            max-width: 140px;
            font-size: 14px;
            
            p{
              font-weight: 700;
              text-align: right;
              text-transform: uppercase;
            }
          }
          .timeline{
            position: relative;
            @include flex(none); // Make sure the specified width will be respected
            width: 60px;
            @include screens-m { width: 80px; }
            @include screens-l { width: 100px; }

            .line{
              position: absolute;
              top: 0;
              left: 0;
              width: 50%;
              height: 100%;
              border-right: 1px solid $colors-blue-navy;
            }

            .bullet-point{
              position: relative;
              width: 30px;
              height: 30px;
              margin: 0 auto;
              @include transform(translateY(-10%));
              @include screens-m { 
                width: 38px;
                height: 38px;
              }
              @include screens-l { 
                width: 45px;
                height: 45px;
              }

              &:before,
              &:after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
              }

              &:after{
                @include disk(100%, $colors-blue-navy);
              }
            }
          }
          .details{
            text-align: left;
            padding-bottom: $content_padding_s;
            @include screens-m { padding-bottom: $content_padding_m; }
            @include screens-l { padding-bottom: $content_padding_l; }
          }

          &:first-of-type{
            .timeline{
              .bullet-point{
                &:before{
                  @include disk(100%, $colors-blue-navy);
                  @include transform(scale(1.2));
                }
                &:after{
                  border: 2px solid white;
                }
              }
            }
          }
          &:last-of-type{
            .timeline{
              .line{
                border-right-style: dashed;
              }
            }
          }
        }
      }
    }
  }
</style>