<template>
  <section class="section-content">
    <div class="content-wrapper">
      <div class="intro-wrapper">
        <h2 v-if="title" v-html="title" />
        <p v-if="body" v-html="body" />
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'ContentBlock',
    components: {},
    data: () => ({}),
    props: {
      title: {
        type: String,
        default: null
      },
      body: {
        type: String,
        default: null
      },
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
@use "@/style/_mixins" as *;
@use "@/style/_variables" as *;

  .section-content{
    .content-wrapper{
      .intro-wrapper{
        padding-bottom: 0;
      }
    }
  }
</style>