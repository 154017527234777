<template>
  <div class="view view-pricing">
    <HeaderBlock
      :localesOptionsVisibility="localesOptionsVisibility"
      @toggle-locales-switch="toggleLocalesSwitch()"
      @close-locales-switch="closeLocalesSwitch()"
      @open-popup-menu="openPopupMenu()" />
    <Hero
        :title="$t('pages.team.title')"
        :body="$t('pages.team.subtitle')"
        :ctas="[]"
        backgroundImage="hero-team.jpg"
        :popupMenuVisibility="popupMenuVisibility"
        @close-popup-menu="closePopupMenu()" />
    <ContentBlock
        title=""
        :body="$t('pages.team.history')" />
    <Timeline
        :title="$t('pages.team.timeline.title')"
        :body="$t('pages.team.timeline.subtitle')"
        :steps="[
          {
          date: $t('pages.team.timeline.2024-03.date'),
          title: $t('pages.team.timeline.2024-03.title'),
          body: $t('pages.team.timeline.2024-03.body'),
        },
          {
          date: $t('pages.team.timeline.2023-09.date'),
          title: $t('pages.team.timeline.2023-09.title'),
          body: $t('pages.team.timeline.2023-09.body'),
        },
        {
          date: $t('pages.team.timeline.2023-02.date'),
          title: $t('pages.team.timeline.2023-02.title'),
          body: $t('pages.team.timeline.2023-02.body'),
        },
        {
          date: $t('pages.team.timeline.2022-06.date'),
          title: $t('pages.team.timeline.2022-06.title'),
          body: $t('pages.team.timeline.2022-06.body'),
        },
        {
          date: $t('pages.team.timeline.2022-03.date'),
          title: $t('pages.team.timeline.2022-03.title'),
          body: $t('pages.team.timeline.2022-03.body'),
        },
        {
          date: $t('pages.team.timeline.2021-05.date'),
          title: $t('pages.team.timeline.2021-05.title'),
          body: $t('pages.team.timeline.2021-05.body'),
        },
        {
          date: $t('pages.team.timeline.2021-01.date'),
          title: $t('pages.team.timeline.2021-01.title'),
          body: $t('pages.team.timeline.2021-01.body'),
        },
        {
          date: $t('pages.team.timeline.2020-03.date'),
          title: $t('pages.team.timeline.2020-03.title'),
          body: $t('pages.team.timeline.2020-03.body'),
        },
        {
          date: $t('pages.team.timeline.2019-04.date'),
          title: $t('pages.team.timeline.2019-04.title'),
          body: $t('pages.team.timeline.2019-04.body'),
        },
        {
          date: $t('pages.team.timeline.2018-10.date'),
          title: $t('pages.team.timeline.2018-10.title'),
          body: $t('pages.team.timeline.2018-10.body'),
        },
        {
          date: $t('pages.team.timeline.2017-03.date'),
          title: $t('pages.team.timeline.2017-03.title'),
          body: $t('pages.team.timeline.2017-03.body'),
        },
        {
          date: $t('pages.team.timeline.2016-11.date'),
          title: $t('pages.team.timeline.2016-11.title'),
          body: $t('pages.team.timeline.2016-11.body'),
        },
        {
          date: $t('pages.team.timeline.2016-06.date'),
          title: $t('pages.team.timeline.2016-06.title'),
          body: $t('pages.team.timeline.2016-06.body'),
        },
        {
          date: $t('pages.team.timeline.2015-11.date'),
          title: $t('pages.team.timeline.2015-11.title'),
          body: $t('pages.team.timeline.2015-11.body'),
        },
        {
          date: $t('pages.team.timeline.2015-10.date'),
          title: $t('pages.team.timeline.2015-10.title'),
          body: $t('pages.team.timeline.2015-10.body'),
        },
        {
          date: $t('pages.team.timeline.2015-09.date'),
          title: $t('pages.team.timeline.2015-09.title'),
          body: $t('pages.team.timeline.2015-09.body'),
        },
        {
          date: $t('pages.team.timeline.2015-04.date'),
          title: $t('pages.team.timeline.2015-04.title'),
          body: $t('pages.team.timeline.2015-04.body'),
        },
        {
          date: $t('pages.team.timeline.2014-11.date'),
          title: $t('pages.team.timeline.2014-11.title'),
          body: $t('pages.team.timeline.2014-11.body'),
        },
        {
          date: $t('pages.team.timeline.2014-08.date'),
          title: $t('pages.team.timeline.2014-08.title'),
          body: $t('pages.team.timeline.2014-08.body'),
        },
        {
          date: $t('pages.team.timeline.2014-07.date'),
          title: $t('pages.team.timeline.2014-07.title'),
          body: $t('pages.team.timeline.2014-07.body'),
        },
        {
          date: $t('pages.team.timeline.2013-03.date'),
          title: $t('pages.team.timeline.2013-03.title'),
          body: $t('pages.team.timeline.2013-03.body'),
        }
        ]"
    />
    <FooterBlock />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import HeaderBlock from '@/components/sections/_Header.vue'
import Hero from '@/components/sections/_Hero.vue'
import ContentBlock from '@/components/sections/_Content.vue'
import Timeline from '@/components/sections/_Timeline.vue'
import FooterBlock from '@/components/sections/_Footer.vue'

export default defineComponent({
  name: 'Team',
  components: {
    HeaderBlock,
    Hero,
    ContentBlock,
    Timeline,
    FooterBlock,
  },
  data: () => ({
    localesOptionsVisibility: false,
    popupMenuVisibility: false
  }),
  props: {},
  computed: {},
  methods: {
    toggleLocalesSwitch(){
      this.localesOptionsVisibility = !this.localesOptionsVisibility;
    },
    closeLocalesSwitch(){
      this.localesOptionsVisibility = false;
    },
    openPopupMenu(){
      this.popupMenuVisibility = true;
    },
    closePopupMenu(){
      this.popupMenuVisibility = false;
    },
  },
  mounted() {
    document.title = this.$t('pages.team.title') + ' - Horses & Competitions';
    window.scrollTo(0, 0)
  }
})
</script>

<style lang="scss">
@use "@/style/_mixins" as *;
@use "@/style/_variables" as *;

.view-home{}
</style>
