<template>
  <div class="contact">
    <a :href="link_social_facebook" target="_blank">
      <font-awesome-icon :icon="['fab', 'facebook-f']" />
    </a>
    <a :href="link_social_instagram" target="_blank">
      <font-awesome-icon :icon="['fab', 'instagram']" />
    </a>
    <a href="mailto:contact@horses-and-competitions.com" target="_blank">
      <font-awesome-icon :icon="['far', 'envelope']" />
    </a>
  </div>
</template>

<script>
  export default {
    name: 'Contact',
    components: {},
    data: () => ({
      link_social_facebook: process.env.VUE_APP_LINK_SOCIAL_FACEBOOK,
      link_social_instagram: process.env.VUE_APP_LINK_SOCIAL_INSTAGRAM
    }),
    props: {},
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
@use "@/style/_mixins" as *;
@use "@/style/_variables" as *;

  .contact{
    a{
      font-size: 26px; // Icons size
      @include screens-m { font-size: 28px; }
      @include screens-l { font-size: 30px; }

      & + a{
        margin-left: 24px; // Add space between two successive icons
        @include screens-m { margin-left: 26px; }
        @include screens-l { margin-left: 28px; }
      }

      .fa-facebook-f{
        @include transform(scale(0.8)); // Scale facebook icon to match other ones in size
      }

      svg {
        width: 30px;
      }
    }
  }
</style>
