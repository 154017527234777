<template>
  <section class="section-comparative-table">
    <div class="content-wrapper">
      <div class="intro-wrapper flat">
        <h2 v-if="title" v-html="title" />
        <p v-if="body" v-html="body" />
      </div>
      <div class="frame compact bevel">
        <table v-if="comparativeList.length > 0">
          <thead>
            <tr>
              <th></th>
              <th>{{ firstColumnLabel }}</th>
              <th>{{ secondColumnLabel }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(comparativeItem, indexComparativeItem) in comparativeList" v-bind:key="indexComparativeItem">
              <td>{{ comparativeItem.label }}</td>
              <td>
                <span class="positive" v-if="comparativeItem.firstColumn.positive">{{ comparativeItem.firstColumn.positive }}</span>
                <span class="negative" v-if="comparativeItem.firstColumn.negative">{{ comparativeItem.firstColumn.negative }}</span>
                <span class="note" v-if="comparativeItem.firstColumn.note">{{ comparativeItem.firstColumn.note }}</span>
              </td>
              <td>
                <span class="positive" v-if="comparativeItem.secondColumn.positive">{{ comparativeItem.secondColumn.positive }}</span>
                <span class="negative" v-if="comparativeItem.secondColumn.negative">{{ comparativeItem.secondColumn.negative }}</span>
                <span class="note" v-if="comparativeItem.secondColumn.note">{{ comparativeItem.secondColumn.note }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="ctas" v-if="ctas.length > 0">
        <a v-for="(cta, index) in ctas" v-bind:key="index" :href="cta.href" :target="cta.target" class="button">{{cta.label}} <IconArrow v-if="cta.arrowIcon" /></a>
      </div>
    </div>
  </section>
</template>

<script>
  import IconArrow from '@/components/elements/_Arrow.vue'

  export default {
    name: 'ComparativeTable',
    components: {
      IconArrow
    },
    data: () => ({}),
    props: {
      title: {
        type: String,
        default: null
      },
      body: {
        type: String,
        default: null
      },
      firstColumnLabel: {
        type: String,
        default: null
      },
      secondColumnLabel: {
        type: String,
        default: null
      },
      comparativeList: {
        type: Array,
        default: () => [{ label: null, firstColumn: null, secondColumn: null }]
      },
      ctas: {
        type: Array,
        default: () => [{ label: null, href: null, arrowIcon: null }]
      },
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
@use "@/style/_mixins" as *;
@use "@/style/_variables" as *;

  .section-comparative-table{
    .content-wrapper{
      .frame{
        table{
          width: 100%;
          
          tr{
            th, td{
              width: calc(100% / 3);
              color: $colors-grey;
              font-weight: 700;
              padding: 10px;

              span{
                & + span{
                  margin-left: 5px; // Add space between two successive span
                }

                &.positive{
                  color: $colors-green-vivid;
                  text-transform: uppercase;
                }
                &.negative{
                  color: $colors-red-vivid;
                  text-transform: uppercase;
                }
                &.note{
                  display: block;
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 1.1em;
                  margin-top: 5px;
                  @include screens-l { 
                    font-size: 12px;
                  }
                  @include screens-l { 
                    font-size: 14px;
                  }
                }
              }

              &:first-child{
                text-align: right;
              }
            }
          }

          tbody{
            tr:not(:last-child){
              td{
                border-bottom: 1px solid $colors-grey-light;
              }
            }
          }
        }
      }
    }
  }
</style>