<template>
  <section class="section-gallery">
    <div class="content-wrapper">
      <div class="intro-wrapper flat">
        <h2 v-if="title" v-html="title" />
        <p v-if="body" v-html="body" />
      </div>
      <ul class="row" v-if="galleryItemsList.length > 0">
        <li class="column" v-for="(galleryItem, indexGalleryItem) in galleryItemsList" v-bind:key="indexGalleryItem">
          <img :src="require('@/assets/images/'+galleryItem.imageSrc)" :alt="galleryItem.imageAlt" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Gallery',
    components: {},
    data: () => ({}),
    props: {
      title: {
        type: String,
        default: null
      },
      body: {
        type: String,
        default: null
      },
      galleryItemsList: {
        type: Array,
        default: () => [{ imageSrc: null, imageAlt: null }]
      },
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
@use "@/style/_mixins" as *;
@use "@/style/_variables" as *;

  .section-gallery{
    .content-wrapper{
      & > .row{
        @include screens-s { 
          @include flex-direction(row); // Stack elements next to each other
          justify-content: center;
          @include flex-wrap(wrap); // Stack elements below each other if there is not enough space on one line
        }

        & > .column{
          img{
            @include screens-s { 
              width: auto;
              height: 65px;
            }
          }
        }
      }
    }
  }
</style>