<template>
  <header>
    <div class="content-wrapper">
      <div class="row">
        <div class="column">
          <MenuSite />
        </div>
        <div class="column">
          <Logo />
        </div>
        <div class="column">
          <MenuCompetitions />
          <LocalesSwitch 
            :localesOptionsVisibility="localesOptionsVisibility" 
            @close-locales-switch="closeLocalesSwitch"
            @toggle-locales-switch="toggleLocalesSwitch" />
          <div class="popup-menu-switch open screens-l-hidden" @click="openPopupMenu">
            <font-awesome-icon :icon="['far', 'bars']" />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import Logo from '@/components/elements/_Logo.vue'
  import MenuSite from '@/components/elements/_MenuSite.vue'
  import MenuCompetitions from '@/components/elements/_MenuCompetitions.vue'
  import LocalesSwitch from '@/components/elements/_LocalesSwitch.vue'

  export default {
    name: 'HeaderBlock',
    components: {
      Logo,
      MenuSite,
      MenuCompetitions,
      LocalesSwitch,
    },
    data: () => ({}),
    props: {
      localesOptionsVisibility: {
        type: Boolean,
        default: false
      }
    },
    computed: {},
    methods: {
      toggleLocalesSwitch() {
        this.$emit('toggle-locales-switch');
      },
      closeLocalesSwitch() {
        this.$emit('close-locales-switch');
      },
      openPopupMenu() {
        this.$emit('open-popup-menu');
      },
    }
  }
</script>

<style lang="scss">
@use "@/style/_mixins" as *;
@use "@/style/_variables" as *;

  header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1; // Make sure it will sit above another section

    .content-wrapper{
      padding-top: $content_padding_s * 0.5;
      padding-bottom: $content_padding_s * 0.5;
      @include screens-m { 
        padding-top: $content_padding_m * 0.5;
        padding-bottom: $content_padding_m * 0.5;
      }
      @include screens-l { 
        padding-top: $content_padding_l * 0.5;
        padding-bottom: $content_padding_l * 0.5;
      }

      & > .row{
        align-items: center; // Elements are centered vertically
        justify-content: space-between; // Elements are spread out so that the space between them is the same
        @include screens-s { @include flex-direction(row); } // Stack elements next to each other

        & > .column{
          align-items: center; // Elements are centered vertically
          
          &:first-of-type,
          &:last-of-type{
            @include display-flex;
            @include screens-l { 
              min-width: 490px; // Has to be specified to make sure that the logo will be centered properly
            };
          }
          &:first-of-type{
            justify-content: flex-start; // Elements are aligned next to each other to the left
            @include screens-s { display: none; };
            @include screens-m { display: none; };
          }
          &:last-of-type{
            justify-content: flex-end; // Elements are aligned next to each other to the right
          }

          .logo{
            width: 70px;
            @include screens-m { width: 95px; };
            @include screens-l { width: 120px; };
          }

          .menu{
            h3{
              display: none;
            }

            ul.row{
              li.column{
                a{
                  position: relative;
                  font-weight: 700;
                  
                  &:hover{
                    text-decoration: none;
                  }

                  &.router-link-exact-active{
                    &:after{
                      content: "";
                      position: absolute;
                      bottom: 0;
                      left: 0;
                      width: 30px;
                      height: 6px;
                      border-radius: 6px;
                      background-color: $colors-blue-navy;
                      overflow: hidden;
                      transform: translateY(8px);
                    }
                  }
                }
              }
            }

            &.site{
              ul.row{
                li.column:not(:last-of-type){ 
                  margin-right: $header_menu-links_gutter_s; // Add space between elements of the site menu
                  @include screens-m { margin-right: $header_menu-links_gutter_m; };
                  @include screens-l { margin-right: $header_menu-links_gutter_l; };
                }
              }
            }
            &.competitions{
              @include screens-s { display: none; };
              @include screens-m { display: none; };

              ul.row{
                li.column:not(:first-of-type){ 
                  margin-left: $header_menu-links_gutter_s; // Add space between elements of the competitions menu
                  @include screens-m { margin-left: $header_menu-links_gutter_m; };
                  @include screens-l { margin-left: $header_menu-links_gutter_l; };
                }
              }
            }
            &.results{
              ul.row{
                li.column{
                  margin-left: $header_menu-links_gutter_s; // Add space between elements of the results menu
                  @include screens-m { margin-left: $header_menu-links_gutter_m; };
                  @include screens-l { margin-left: $header_menu-links_gutter_l; };

                  .button{
                    color: $colors-blue-navy;
                    background-color: white;
                    @include screens-l { 
                      color: white;
                      background-color: $colors-blue-navy;
                    };
                  }
                }
              }
            }
          }

          .locales-switch,
          .popup-menu-switch{
            margin-left: $header_menu-links_gutter_s;
            @include screens-m { margin-left: $header_menu-links_gutter_m; };
            @include screens-l { margin-left: $header_menu-links_gutter_l; };
          }

          .popup-menu-switch{
            color: $colors-blue-navy;
            font-size: 25px;
            line-height: 32px;

            &:hover{
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>