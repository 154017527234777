<template>
  <div class="view view-pricing">
    <HeaderBlock
      :localesOptionsVisibility="localesOptionsVisibility"
      @toggle-locales-switch="toggleLocalesSwitch()"
      @close-locales-switch="closeLocalesSwitch()"
      @open-popup-menu="openPopupMenu()" />
    <Hero
      :title="$t('pages.price.title')"
      :body="$t('pages.price.subtitle')"
      :ctas="[]"
      backgroundImage="hero-pricing.jpg"
      :popupMenuVisibility="popupMenuVisibility"
      @close-popup-menu="closePopupMenu()" />
    <Plan
      :title="$t('pages.home.price.title')"
      :body="$t('pages.home.price.body')"
      :pricing="{
        title: $t('pages.home.price.pricing.title'),
        price: $t('pages.home.price.pricing.price'),
        label: $t('pages.home.price.pricing.label'),
        conditions: $t('pages.home.price.pricing.conditions'),
        cta: { label: $t('pages.home.cta.link_subscribe'), href: link_subscription, arrowIcon: true, target: '_blank' }
      }"
      :list="[
        { body: $t('pages.home.price.pricing.list.1') },
        { body: $t('pages.home.price.pricing.list.2') },
        { body: $t('pages.home.price.pricing.list.3') },
        { body: $t('pages.home.price.pricing.list.4') },
        { body: $t('pages.home.price.pricing.list.5') }
        ]"
      backgroundImage=""
      :isFramed="false" />
    <ComparativeTable
      :title="$t('pages.price.diff.title')"
      :body="$t('pages.price.diff.subtitle')"
      firstColumnLabel="Horses &amp; Competitions"
      secondColumnLabel="Winjump"
      :comparativeList="[
        {
          label: $t('pages.price.trust.features.1'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.2'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.3'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.4'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.5'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.6'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.7'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.8'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.9'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.10'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.11'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: null, negative: $t('pages.price.trust.no'), note: null }
        },{ 
          label: $t('pages.price.trust.features.12'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.13'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: null, negative: $t('pages.price.trust.option'), note: null }
        },{ 
          label: $t('pages.price.trust.features.14'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{
          label: $t('pages.price.trust.features.15'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{
          label: $t('pages.price.trust.features.16'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: null, negative: $t('pages.price.trust.option'), note: null }
        },{
          label: $t('pages.price.trust.features.17'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: null, negative: $t('pages.price.trust.option'), note: null }
        },{ 
          label: $t('pages.price.trust.features.18'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: null }
        },{ 
          label: $t('pages.price.trust.features.19'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: $t('pages.price.trust.features.19_arg1') },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: $t('pages.price.trust.features.19_arg2') }
        },{
          label: $t('pages.price.trust.features.20'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: $t('pages.price.trust.features.20_arg1') },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: 'Windows 7 et supérieur' }
        },{
          label: $t('pages.price.trust.features.21'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: $t('pages.price.trust.features.20_arg1') },
          secondColumn: { positive: null, negative: $t('pages.price.trust.no'), note: null }
        },{
          label: $t('pages.price.trust.features.22'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: $t('pages.price.trust.features.22_arg1') },
          secondColumn: { positive: null, negative: $t('pages.price.trust.no'), note: null }
        },{
          label: $t('pages.price.trust.features.23'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: $t('pages.price.trust.features.23_arg1') },
          secondColumn: { positive: null, negative: $t('pages.price.trust.no'), note: null }
        },{ 
          label: $t('pages.price.trust.features.24'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: $t('pages.price.trust.features.24_arg1') },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: $t('pages.price.trust.features.24_arg2') }
        },{
          label: $t('pages.price.trust.features.25'),
          firstColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: $t('pages.price.trust.features.24_arg1') },
          secondColumn: { positive: $t('pages.price.trust.yes'), negative: null, note: $t('pages.price.trust.features.25_arg2') }
        }]"
      :ctas="[
        { label: $t('pages.price.cta.link_subscribe'), href: link_subscription, arrowIcon: true, target: '_blank' }]" />
    <MapBlock
      :title="$t('pages.price.trust.title')"
      :body="$t('pages.price.trust.subtitle')" />
    <FooterBlock />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

  import HeaderBlock from '@/components/sections/_Header.vue'
  import Hero from '@/components/sections/_Hero.vue'
  import Plan from '@/components/sections/_Plan.vue'
  import ComparativeTable from '@/components/sections/_ComparativeTable.vue'
  import MapBlock from '@/components/sections/_Map.vue'
  import FooterBlock from '@/components/sections/_Footer.vue'

  export default defineComponent({
    name: 'Home',
    components: {
      HeaderBlock,
      Hero,
      Plan,
      ComparativeTable,
      MapBlock,
      FooterBlock,
    },
    data: () => ({
      localesOptionsVisibility: false,
      popupMenuVisibility: false,
      link_subscription: process.env.VUE_APP_LINK_SUBSCRIPTION
    }),
    props: {},
    computed: {},
    methods: {
      toggleLocalesSwitch(){
        this.localesOptionsVisibility = !this.localesOptionsVisibility;
      },
      closeLocalesSwitch(){
        this.localesOptionsVisibility = false;
      },
      openPopupMenu(){
        this.popupMenuVisibility = true;
      },
      closePopupMenu(){
        this.popupMenuVisibility = false;
      },
    },
    mounted() {
      document.title = this.$t('pages.price.title') + ' - Horses & Competitions';
      window.scrollTo(0, 0)
    }
  })
</script>

<style lang="scss">
@use "@/style/_mixins" as *;
@use "@/style/_variables" as *;

.view-pricing{}
</style>
